<template>
	<div class="recipes">
		<Header />
		<div class="content">
			<div
				v-infinite-scroll="loadMore"
				:infinite-scroll-disabled="loadingData"
				:infinite-scroll-distance="0"
				infinite-scroll-throttle-delay="1600"
			>
				<MainColumn style="min-height: 100vh">
					<p class="top-title color-aili-white font-size-50">
						Articoli
					</p>
					<v-fade-transition>
						<CategoryHeaderArticles v-if="articleCategories.length > 0" @onChange="onCategoryChange" />
					</v-fade-transition>
					<v-fade-transition>
						<CategoryContentArticles
							v-if="term == 'esperti' || tags || posts"
							:authors="authors ? authors : false"
							:tags="tags ? tags : false"
							:posts="posts ? posts : false"
							:end="end"
							:term="term"
							:selectedCategoryTitle="selectedCategoryTitle"
							:per_page="per_page"
						/>
					</v-fade-transition>
				</MainColumn>
			</div>
		</div>
	</div>
</template>

<script>
import Header from '@/components/ui/Header.vue';
import CategoryHeaderArticles from '@/components/ui/CategoryHeaderArticles.vue';
import CategoryContentArticles from '@/components/ui/CategoryContentArticles.vue';

export default {
	components: {
		Header,
		CategoryHeaderArticles,
		CategoryContentArticles,
	},
	metaInfo() {
		let postFakeData = [];
		postFakeData['yoast_meta'] = [
			{
				content: 'Associazione AILI',
				property: 'og:site_name',
			},
			{
				content: 'it_IT',
				property: 'og:locale',
			},
			{
				content: 'Home - Archivio Articoli',
				property: 'og:title',
			},
		];
		return this.formatYoastMeta(postFakeData);
	},
	data() {
		return {
			authors: false,
			end: false,
			experts_loaded: false,
			max_page: 999,
			page: 1,
			per_page: 3,
			posts: false,
			selectedCategoryTitle: false,
			tags: false,
			term: false,
			term_id: false,
		};
	},
	async mounted() {
		this.updateProperty({
			property: 'pageColor',
			value: 'aili-purple',
		});
		await this.getArticleCategories();

		let categoryParams = this.$route.params.category ? this.$route.params.category : '';
		let selectedCategory;

		this.articleCategories.map((category) => {
			if (category.slug == categoryParams) {
				selectedCategory = category;
			}
		});
		await this.getContent(selectedCategory);
	},
	methods: {
		async getContent(category) {
			this.updateProperty({
				property: 'loadingData',
				value: true,
			});
			this.page = 1;
			this.term = category ? category.slug : '';
			let term = this.term.slug ? this.convertSlug(this.term.slug) : 'articoli';

			this.selectedCategoryTitle = category ? category.name : '';

			switch (term) {
				case 'esperti': {
					await this.getAuthors();
					break;
				}

				case 'articoli': {
					await this.getAuthors();
					await this.getPosts();
					break;
				}

				default: {
					await this.getPosts();
					break;
				}
			}

			this.updateProperty({
				property: 'loadingData',
				value: false,
			});
		},
		async getAuthors() {
			let response_authors = await this.$api.get('/qzr-data/authors/experts');
			this.authors = response_authors.data;
		},
		async getPosts() {
			let category_id = await this.$api.get('/wp/v2/categories', {
				params: {
					slug: this.term,
				},
			});
			this.term_id = category_id.data[0].id;
			let params = {
				hide_empty: true,
				per_page: this.per_page,
			};
			if (this.term_id) {
				params.categories = this.term_id;
			}
			let response_posts = await this.$api.get('/wp/v2/posts', {
				params: params,
			});
			this.max_page = response_posts.headers['x-wp-totalpages'];
			this.posts = response_posts.data;
		},
		async loadMore() {
			try {
				if (parseInt(this.page) < parseInt(this.max_page) && this.term && this.articleCategories.length > 0) {
					this.page = this.page + 1;

					let params = {
						hide_empty: true,
						per_page: this.per_page,
						page: this.page,
					};
					if (this.term_id) {
						params.categories = this.term_id;
					}
					let response = await this.$api.get('/wp/v2/posts', {
						params: params,
					});
					let result = this.posts.concat(response.data);
					this.posts = result;
				}
			} catch (e) {
				console.error(e);
			}
		},
		async onCategoryChange(category) {
			await this.getContent(category);
		},
	},
};
</script>

<style lang="scss" scoped>
.recipes {
	min-height: 100vh;
	padding-bottom: $footer-distance;
}

h1 {
	padding: 0;
	padding-bottom: $spacing-1;
}

h2,
h3 {
	margin-bottom: $spacing-1;
}

.panel {
	padding: $spacing-1;
	position: relative;
}
</style>
