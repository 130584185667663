<template>
	<v-fade-transition>
		<div class="content-shadow" v-if="swiperVisible">
			<div v-if="searchTerm">
				<div :class="'panel background-aili-white'">
					<h2 :class="'color-aili-purple font-size-35'">Risultati per: {{ searchTerm }}</h2>
					<NoResultFound v-if="searchResults.length == 0" />
					<div v-for="(result, j) in searchResults" :key="j">
						<CardResultArticle
							:slug="result.slug"
							:preview_data="result.preview_data"
							:author="result.author_data"
							:image="result.featured_media_data"
							:title="result.title"
							:content="result.post_excerpt"
							:categories="result.category_data"
						/>
					</div>
				</div>
			</div>
			<div v-else-if="(!term && term === '') || typeof term === 'undefined'">
				<div :class="'panel background-aili-purple-light'">
					<h2 :class="'color-aili-purple font-size-35'">
						I nostri Esperti
					</h2>
					<div class="buttons">
						<div @click="prevPage">
							<Arrow type="left" color="#7209b7" />
						</div>
						<div @click="nextPage">
							<Arrow @click="prevPage" type="right" color="#7209b7" />
						</div>
					</div>
					<swiper class="category-content-article-swiper" ref="swiperArticleContent" :options="swiperOption">
						<swiper-slide v-for="(author, i) in authors" :key="i">
							<a
								class="author"
								:href="getUrl('articoli/esperto/' + author.slug)"
								@click="(e) => goTo('Expert', { slug: author.slug }, e)"
							>
								<div
									class="author-image"
									:style="'background-image:url(' + author.qzr_image.thumbnail + ')'"
								></div>
								<p :class="'color-aili-purple font-size-16'" style="margin-bottom: 40px">
									{{ author.first_name + ' ' + author.last_name }}
								</p>
							</a>
						</swiper-slide>
						<div class="swiper-pagination" slot="pagination"></div>
					</swiper>
				</div>

				<div class="panel background-aili-white">
					<Wave position="top" :color="getHexColor('aili-purple-light')" />
					<h2 :class="'color-aili-purple font-size-35'">
						In primo piano
					</h2>
					<LastPosts :parentBlock="{ attrs: { background: '#fff' } }" />
				</div>

				<div v-for="(category, i) in articleCategories" :key="i">
					<div
						:class="{
							panel: true,
							['background-aili-purple-light']: i % 2 == 0,
							'background-aili-white': i % 2 > 0,
						}"
						v-if="category.slug && category.slug !== 'esperti'"
					>
						<h3 :class="'color-aili-purple font-size-25'">
							{{ category.name }}
						</h3>
						<LastPostsByCategory
							:color="i % 2 > 0 ? 'aili-purple-light' : 'aili-white'"
							:category="category.slug"
						/>
					</div>
				</div>
			</div>
			<div v-if="term === 'esperti'">
				<div class="panel background-aili-white">
					<h4 class="color-aili-purple font-size-35">I nostri Esperti</h4>
				</div>
				<div
					class="panel"
					:class="{
						['background-aili-white']: i % 2 > 0,
						'background-aili-purple-light': i % 2 == 0,
					}"
					v-for="(author, i) in authors"
					:key="i"
				>
					<ExpertResult v-if="author" :author="author" color="aili-purple" />
				</div>
			</div>
			<div v-else-if="selectedCategoryTitle !== 'Tutti' && posts.length > 0">
				<div class="panel background-aili-white">
					<h1
						v-if="$vuetify.breakpoint.width > 1024"
						class="color-aili-purple"
						v-html="selectedCategoryTitle"
					/>
					<div v-for="(post, i) in posts" :key="i">
						<CardResultArticle
							:slug="post.slug"
							:preview_data="post.preview_data"
							:author="post.author_data"
							:url="post.relative_url"
							:image="post.featured_media_data"
							:title="post.title.rendered"
							:content="post.post_excerpt"
							:categories="post.category_data"
						/>
					</div>
				</div>
			</div>
		</div>
	</v-fade-transition>
</template>

<script>
import Arrow from '@/components/ui/Arrow.vue';
import NoResultFound from '@/components/ui/NoResultFound.vue';
import LastPostsByCategory from '@/components/blocks/lazyblocks/lazyblock_last_posts_by_category.vue';
import LastPosts from '@/components/blocks/lazyblocks/lazyblock_last_posts.vue';
import CardResultArticle from '@/components/ui/CardResultArticle.vue';
import ExpertResult from '@/components/ui/ExpertResult.vue';
import Wave from '@/components/ui/Wave.vue';

export default {
	components: {
		Arrow,
		NoResultFound,
		Wave,
		LastPosts,
		LastPostsByCategory,
		CardResultArticle,
		ExpertResult,
	},
	props: ['posts', 'end', 'authors', 'tags', 'term', 'selectedCategoryTitle'],

	data() {
		return {
			searchResults: [],
			swiperOption: {
				slidesPerView: 1,
				spaceBetween: 25,
				pagination: {
					el: '.swiper-pagination',
					clickable: false,
				},
				breakpoints: {
					550: {
						slidesPerView: 2,
					},
					768: {
						slidesPerView: 3,
					},
					1024: {
						slidesPerView: 5,
					},
				},
			},
			swiperVisible: false,
		};
	},
	computed: {
		swiper() {
			return this.$refs.swiperArticleContent.$swiper;
		},
	},
	watch: {
		searchTerm: async function(newValue) {
			let params = {
				subtype: 'post',
				search: newValue,
				per_page: 100,
			};

			if (newValue) {
				let response_search = await this.$api.get('wp/v2/search', {
					params: params,
				});

				this.searchResults = response_search.data;
			} else {
				this.searchResults = [];
			}
		},
	},
	mounted() {
		this.updateProperty({
			property: 'loadingData',
			value: false,
		});
		//Without this pagination is not working
		setTimeout(() => (this.swiperVisible = true));
	},
	methods: {
		changeUrl(url) {
			window.location.href = url;
		},
		nextPage() {
			this.swiper.slideNext();
		},
		prevPage() {
			this.swiper.slidePrev();
		},
	},
};
</script>

<style lang="scss" scoped>
.buttons {
	position: absolute;
	@media (max-width: $tablet-m) {
		display: none;
	}
}
.pagination {
	display: none;
	@media (max-width: $tablet-m) {
		display: block;
	}
}

.recipes {
	min-height: 100vh;
}

a {
	text-decoration: none;
}
h1 {
	margin-bottom: 50px;
}

h2,
h3 {
	margin-bottom: $spacing-1;
}

h4 {
	margin: 0;
}

.panel {
	padding: $spacing-1;
	position: relative;
	@media (max-width: $tablet-l) {
		padding: $spacing-0 !important;
	}
}

.author {
	cursor: pointer;
	.author-image {
		background-size: cover;
		border-radius: 100%;
		display: inline-block;
		filter: saturate(0);
		width: 100%;
		padding-top: 100%;
	}
	p {
		text-align: center;
	}
}

.tags {
	display: inline-flex;
	width: 100%;
	padding: $spacing-1;
	justify-content: flex-start;

	.tag {
		margin-right: $spacing-0;
		position: relative;
		width: 30%;
		height: 400px;
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
		cursor: pointer;
	}

	h3 {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: #fff;
	}
	.gradient:before {
		content: '';
		background: linear-gradient(0deg, #000 0%, rgba(0, 212, 255, 0) 100%);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}
</style>

<style lang="scss">
.category-content-article-swiper {
	//Class inside swiper need to be declared outside of scoped
	.swiper-pagination-bullet-active {
		background-color: $aili-purple;
	}
	width: calc(100% - 100px);
}
</style>
