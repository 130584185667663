<template>
	<MainColumn>
		<div class="expert" v-if="author.qzr_image">
			<div class="expert-info" @click="goTo('Expert', { slug: author.slug })">
				<div class="expert-image" :style="'background-image:url(' + author.qzr_image.thumbnail + ')'"></div>
				<p
					:class="'color-black font-size-15'"
					v-if="author.description_tiny"
					v-html="author.description_tiny[0]"
				/>
				<div
					class="permalink-mobile font-size-14 color-aili-purple"
					@click="goTo('Expert', { slug: author.slug })"
				>
					Scopri gli articoli
				</div>
			</div>
			<div class="header-expert">
				<h4 class="color-aili-purple font-size-25" @click="goTo('Expert', { slug: author.slug })">
					{{ author.first_name + ' ' + author.last_name }}
				</h4>
				<div class="permalink font-size-14 color-aili-purple" @click="goTo('Expert', { slug: author.slug })">
					Scopri gli articoli
				</div>
			</div>
			<div class="last-news" v-if="last_posts">
				<div v-for="(card, j) in last_posts" :key="j" class="news-wrap">
					<CardSingleArticle
						:slug="card.slug"
						:image="card.featured_media_data.medium"
						:title="card.title.rendered"
						:author="card.author_data"
						:preview_data="card.preview_data"
						:refresh="true"
						:categories="card.category_data"
					/>
				</div>
			</div>
			<br class="clear" />
		</div>
	</MainColumn>
</template>
<script>
import CardSingleArticle from '@/components/ui/CardSingleArticle.vue';

export default {
	props: ['author', 'color'],
	components: { CardSingleArticle },
	data() {
		return {
			last_posts: false,
		};
	},
	async mounted() {
		this.last_posts = await this.getLastPosts();
	},
	methods: {
		async getLastPosts() {
			let last_posts = await this.$api.get('wp/v2/posts', {
				params: {
					'author[]': this.author.id,
					per_page: 3,
				},
			});

			return last_posts.data;
		},
	},
};
</script>
<style lang="scss" scoped>
h4,
.expert-info {
  cursor: pointer;
}

.expert {
  padding: $spacing-1 0;

  @media (max-width: $tablet-m) {
    display: inline-block;
  }
  @media (max-width: $tablet-l) {
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .expert-image {
    width: 200px;
    height: 200px;
    margin-bottom: $spacing-0;
    background-size: cover;
    border-radius: 100%;
    @media (max-width: $tablet-l) {
      min-width: 200px;
    }

    @media (max-width: $tablet-m) {
      width: 200px;
      min-width: 100px;
      height: 200px;
      margin: auto;
      margin-bottom: 25px;
    }
  }
}

.expert-info {
  float: left;
  width: 30%;
  padding-right: $spacing-0;
  @media (max-width: $tablet-l) {
    display: inline-flex;
    order: 2;
    width: 100%;
    p {
      position: relative;
      max-width: 400px;
      padding-left: 3rem;
    }
  }
  @media (max-width: $tablet-m) {
    display: inline-block;
    padding: 0;
    p {
      padding: 0;
    }
  }
}
h4 {
  @media (max-width: $tablet-m) {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
}
.header-expert {
  display: inline-flex;
  justify-content: space-between;
  width: 70%;
  margin-bottom: $spacing-1;
  .permalink {
    display: none;
    text-decoration: none;
    text-transform: uppercase;
  }
  @media (max-width: $tablet-l) {
    width: 100%;
  }
  @media (max-width: $tablet-m) {
    margin-bottom: 25px;
  }

  .permalink {
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    @media (max-width: $tablet-m) {
      display: none;
    }
  }
}

.last-news {
  float: right;
  display: inline-flex;
  justify-content: flex-start;
  width: 100%;
  margin-right: -30%;

  @media (max-width: $desktop) {
    .news-card {
      display: none;
    }
  }

  @media (max-width: $tablet-l) {
    display: none;
  }
  .news-card {
    display: inline-block;
    width: 30%;
    min-height: 350px;
    margin-right: $spacing-0;
    margin-bottom: $spacing-1;
    background: #fff;

    .news-image {
      float: left;
      width: 100%;
      padding-top: 60%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
  }
  .news-wrap {
    max-width: 328px;
  }
}
.permalink-mobile {
  display: none;
  width: 100%;
  text-align: center;
  @media (max-width: $tablet-l) {
    display: block;
    text-decoration: none;
    text-transform: uppercase;
  }
}
</style>
