var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.$route.name != 'Articles')?_c('header',{staticClass:"panel-header"},[_c('div',{staticClass:"title-wrap"},[_c('h2',{staticClass:"font-size-35"},[_vm._v(" Articoli ")])]),_c('div',{staticClass:"button-wrap"},[_c('Button',{attrs:{"color":"aili-purple","inverted":_vm.$route.name != 'Articles',"href":"articoli","value":"Scopri"}})],1)]):_vm._e(),(_vm.$vuetify.breakpoint.width > 1024)?_c('div',{class:{
			posts: true,
			'one-row': _vm.posts ? _vm.posts.length <= 5 : false
		}},_vm._l((_vm.posts),function(post,i){
		var _obj;
return _c('a',{key:i,staticClass:"post",class:( _obj = {}, _obj['element-' + i] = true, _obj['background-aili-purple-light'] = 
					_vm.parentBlock.attrs.background == '#fff', _obj ),attrs:{"href":'articolo/' + post.post_name,"title":post.post_title},on:{"click":function (e) { return _vm.goTo('Article', { slug: post.post_name }, e); }}},[_c('div',{class:{
					gradient: !post.featured_media_data.error,
					'has-image': !post.featured_media_data.error
				}},[_c('div',{staticClass:"post-image",style:(post.backgroundStyle)})]),_c('v-card-text',{class:'pa-0 ' +
						'color-' +
						(!post.featured_media_data.error
							? 'aili-white'
							: 'aili-purple')},[_c('div',{staticClass:"description"},[_c('div',{staticClass:"categories tiny"},[_c('div',{staticClass:"post-type"},[_vm._v("Articoli")]),_c('div',{staticClass:"separator"}),_vm._l((post.category_data),function(category,j){return _c('div',{key:j,staticClass:"category"},[_c('span',{staticClass:"category-name"},[_vm._v(_vm._s(category.name))]),(j < post.category_data.length - 1)?_c('span',{staticClass:"category-comma"},[_vm._v(",")]):_vm._e()])})],2),_c('h3',{class:i == 0 ? 'font-size-25' : 'font-size-16',domProps:{"innerHTML":_vm._s(post.post_title)}})])])],1)}),0):_c('swiper',{ref:"swiperPost",staticClass:"swiper-last-posts",attrs:{"options":_vm.swiperOption},on:{"slideChange":_vm.onSlideChange}},[_vm._l((_vm.posts),function(post,i){return _c('swiper-slide',{key:i},[_c('v-card',{staticClass:"post",attrs:{"tile":""},on:{"click":function($event){return _vm.goTo('Article', { slug: post.post_name })}}},[_c('div',{class:{
						gradient: !post.featured_media_data.error,
						'has-image': !post.featured_media_data.error
					}},[_c('div',{staticClass:"post-image",style:({
							'background-image':
								'url(' +
								post.featured_media_data.large +
								')'
						})}),_c('v-card-text',{class:'pa-0 ' +
								'color-' +
								(!post.featured_media_data.error
									? 'aili-white'
									: _vm.parentBlock.attrs.background)},[_c('div',{staticClass:"description"},[_c('div',{staticClass:"categories  tiny"},[_c('div',{staticClass:"post-type "},[_vm._v("Articoli")]),_c('div',{staticClass:"separator"}),_vm._l((post.category_data),function(category,j){return _c('div',{key:j,staticClass:"category"},[_c('span',{staticClass:"category-name"},[_vm._v(_vm._s(category.name))]),(
											j <
												post.category_data.length -
													1
										)?_c('span',{staticClass:"category-comma"},[_vm._v(",")]):_vm._e()])})],2),(post.post_title)?_c('h3',{domProps:{"innerHTML":_vm._s(post.post_title)}}):_vm._e()])])],1)])],1)}),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }