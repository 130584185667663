<template>
	<v-container>
		<header class="panel-header" v-if="$route.name != 'Articles'">
			<div class="title-wrap">
				<h2 class="font-size-35">
					Articoli
				</h2>
			</div>
			<div class="button-wrap">
				<Button
					color="aili-purple"
					:inverted="$route.name != 'Articles'"
					href="articoli"
					value="Scopri"
				/>
			</div>
		</header>
		<div
			v-if="$vuetify.breakpoint.width > 1024"
			:class="{
				posts: true,
				'one-row': posts ? posts.length <= 5 : false
			}"
		>
			<a
				v-for="(post, i) in posts"
				@click="e => goTo('Article', { slug: post.post_name }, e)"
				:key="i"
				class="post"
				:class="{
					['element-' + i]: true,
					'background-aili-purple-light':
						parentBlock.attrs.background == '#fff'
				}"
				:href="'articolo/' + post.post_name"
				:title="post.post_title"
			>
				<div
					:class="{
						gradient: !post.featured_media_data.error,
						'has-image': !post.featured_media_data.error
					}"
				>
					<div class="post-image" :style="post.backgroundStyle"></div>
				</div>
				<v-card-text
					:class="
						'pa-0 ' +
							'color-' +
							(!post.featured_media_data.error
								? 'aili-white'
								: 'aili-purple')
					"
				>
					<div class="description">
						<div class="categories tiny">
							<div class="post-type">Articoli</div>

							<div class="separator"></div>
							<div
								class="category"
								v-for="(category, j) in post.category_data"
								:key="j"
							>
								<span class="category-name">{{
									category.name
								}}</span>
								<span
									class="category-comma"
									v-if="j < post.category_data.length - 1"
									>,</span
								>
							</div>
						</div>
						<h3
							:class="i == 0 ? 'font-size-25' : 'font-size-16'"
							v-html="post.post_title"
						/>
					</div>
				</v-card-text>
			</a>
		</div>

		<swiper
			v-else
			@slideChange="onSlideChange"
			ref="swiperPost"
			class="swiper-last-posts"
			:options="swiperOption"
		>
			<swiper-slide v-for="(post, i) in posts" :key="i">
				<v-card
					tile
					class="post"
					@click="goTo('Article', { slug: post.post_name })"
				>
					<div
						:class="{
							gradient: !post.featured_media_data.error,
							'has-image': !post.featured_media_data.error
						}"
					>
						<div
							class="post-image"
							:style="{
								'background-image':
									'url(' +
									post.featured_media_data.large +
									')'
							}"
						></div>
						<v-card-text
							:class="
								'pa-0 ' +
									'color-' +
									(!post.featured_media_data.error
										? 'aili-white'
										: parentBlock.attrs.background)
							"
						>
							<div class="description">
								<div class="categories  tiny">
									<div class="post-type ">Articoli</div>

									<div class="separator"></div>
									<div
										class="category"
										v-for="(category,
										j) in post.category_data"
										:key="j"
									>
										<span class="category-name">{{
											category.name
										}}</span>
										<span
											class="category-comma"
											v-if="
												j <
													post.category_data.length -
														1
											"
											>,</span
										>
									</div>
								</div>
								<h3
									v-if="post.post_title"
									v-html="post.post_title"
								/>
							</div>
						</v-card-text>
					</div>
				</v-card>
			</swiper-slide>
			<div class="swiper-pagination" slot="pagination"></div>
		</swiper>
	</v-container>
</template>

<script>
export default {
	name: "LazyblockLastPostsBlock",
	props: ["block", "parentBlock"],
	data() {
		return {
			currentIndex: 0,
			posts: [],
			swiperOption: {
				slidesPerView: 1,
				spaceBetween: 60,
				centeredSlides: false,
				pagination: {
					el: ".swiper-pagination",
					clickable: true
				},
				breakpoints: {
					768: {
						slidesPerView: 2,
						spaceBetween: 20
					}
				}
			}
		};
	},
	methods: {
		onSlideChange() {
			this.currentIndex = this.swiper.activeIndex;
		},
		changeUrl(url) {
			window.location.href = url;
		},
		onPaginationClick(index) {
			this.swiper.slideTo(index);
		},
		nextPage() {
			this.swiper.slideNext();
		},
		prevPage() {
			this.swiper.slidePrev();
		}
	},
	computed: {
		swiper() {
			return this.$refs.swiperPost.$swiper;
		}
	},
	async mounted() {
		let stickyPosts = await this.$api.get("/qzr-data/sticky/post");
		this.posts = stickyPosts.data.slice(0, 5);
		this.posts.map(post => {
			post.categories = post["category_data"];
		});

		this.posts.map(post => {
			if (post.featured_media_data.large) {
				post.backgroundStyle =
					"background-image: url(" +
					post.featured_media_data.large +
					")";
			} else {
				post.backgroundStyle = "background: #fff";
			}
		});
	}
};
</script>

<style lang="scss" scoped>
#app {
	.panel-header {
		margin-bottom: $spacing-1;
		@media (max-width: 768px) {
			margin-bottom: $spacing-0;
		}
		h2 {
			margin: 0;
		}
		.title-wrap {
			width: calc(100% - 100px);
			display: inline-block;
		}

		.button-wrap {
			width: 100px;
			text-align: right;
			display: inline-block;
		}
	}
	.categories {
		line-height: 1.3;
		font-size: 12px;
		text-transform: uppercase;
		color: #b058e9;
	}
	.category {
		margin: 0;
	}
	.description {
		width: calc(100% - 50px);
		position: absolute;
		bottom: $spacing-0;
		left: $spacing-0;
		z-index: 10;
	}
	.element-0 {
		grid-row: 1 / span 2;
		grid-column: 1 / span 2;
	}

	.element-1 {
		grid-row: 1 / span 1;
		grid-column: 3 / span 1;
	}

	.element-2 {
		grid-row: 1 / span 1;
		grid-column: 4 / span 1;
	}

	.element-3 {
		grid-row: 2 / span 1;
		grid-column: 4 / span 1;
	}
	.gradient.has-image:before {
		content: "";
		background: linear-gradient(0deg, #000 0%, rgba(0, 212, 255, 0) 100%);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	h3 {
		margin: 0;
	}

	.posts {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: repeat(2, minmax(0, 1fr));
		column-gap: 15px;
		row-gap: 15px;

		@media (max-width: $tablet-m) {
			display: inline-block;
		}
	}

	.post {
		cursor: pointer;
		display: inline-block;
		position: relative;
		width: 100%;

		@media (max-width: $tablet-m) {
			display: inline-block;
			margin-bottom: 25px;
		}
	}

	.post.has-background-image:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 1;
	}

	.post-image {
		z-index: -1;
		height: 100%;
		padding-top: 100%;
		background-size: cover;
	}
}
</style>

<style lang="scss">
.swiper-last-posts {
	.swiper-pagination-bullet-active {
		background-color: $aili-purple-light;
	}
}
</style>
